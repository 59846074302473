import { getNumberFormat } from './formats';
const numberFormat = getNumberFormat();

export function formatNumber(value: number) {
  if (value == null || value === undefined) {
    return '';
  }

  return numberFormat.format(value);
}
