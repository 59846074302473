import { formatNumber } from '@sus-core/utils/number';
import clsx from 'clsx';
import React from 'react';
import { Collapsible } from '../content/Collapsible';
import { Icons } from '../icon/Icons';
const ColorSwatchLazy = React.lazy(() => import('./colors/ColorSwatch'));

type ProductAttributes =
  GatsbyTypes.ProductDetailsQuery['sus']['attributeValues'];

export function ProductAttributes({
  className,
  attributes,
}: {
  className?: string;
  attributes: ProductAttributes;
}) {
  return (
    <div className={className}>
      <Collapsible show title="Details">
        <div className="grid grid-cols-1 xs:grid-cols-2 gap-4 items-baseline">
          {attributes
            ?.map(a => ({ ...a, value: JSON.parse(a.value) }))
            .filter(a => a.value != null && a.value != false)
            .sort((a, b) => a.position - b.position)
            .map(attr => (
              <ProductAttribute key={attr.code} attribute={attr} />
            ))}
        </div>
      </Collapsible>
    </div>
  );
}

function ProductAttribute({ attribute }: { attribute: ProductAttributes[0] }) {
  return (
    <div className="flex flex-col">
      <span className="flex-1 font-semibold">{attribute.label}:</span>
      {attribute.input_type === 'multiselect' ? (
        <span className="flex-1 flex flex-col">
          {attribute.value
            .split(',')
            .map(v => v.trim())
            .map(v => (
              <AttributeValue
                className="flex justify-start"
                key={v}
                attribute={{ ...attribute, value: v }}
              />
            ))}
        </span>
      ) : (
        <AttributeValue className="flex-1" attribute={attribute} />
      )}
    </div>
  );
}

function AttributeValue({
  attribute,
  className,
}: {
  className?: string;
  attribute: ProductAttributes[0];
}) {
  const isSSR = typeof window === 'undefined';

  if (attribute.code.includes('farbe') && !isSSR) {
    return (
      <React.Suspense fallback={<div />}>
        <ColorSwatchLazy
          ralName={attribute.value}
          className={clsx('', className)}
        />
      </React.Suspense>
    );
  }

  if (attribute.attribute_type === 'Float') {
    return <>{formatNumber(parseFloat(attribute.value))}</>;
  }

  if (attribute.input_type === 'boolean') {
    return attribute.value === 'Yes' ? (
      <Icons.Check className="svg-txt-color text-success" />
    ) : (
      <Icons.Close className="svg-txt-color  text-red-1" />
    );
  }

  return <>{attribute.value}</>;
}
