import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export interface ProductImagesProps
  extends Pick<HTMLAttributes<HTMLElement>, 'className' | 'style'> {
  product?: Pick<
    GatsbyTypes.ProductDetailsQuery['magentoProduct'],
    'image' | 'media_gallery'
  >;
}

import clsx from 'clsx';
import { Icons } from '../icon/Icons';

export function ProductImages({
  className,
  product,
  ...rest
}: ProductImagesProps) {
  const defaultImage = product.image;
  const sortedImages = useMemo(
    () =>
      product.media_gallery
        .filter(item => !!item && !item.disabled)
        .sort((a, b) => {
          if (!a || !b) return 0;

          if (a.image.id === defaultImage.id) {
            return -1;
          }

          if (b.image.id === defaultImage.id) {
            return 1;
          }

          return a.position - b.position;
        }),
    [defaultImage.id, product.media_gallery]
  );
  // @TODO use fallback image if no image is present
  const [image, setImage] = useState<
    ProductImagesProps['product']['media_gallery'][0] | null
  >(
    sortedImages?.find(i => {
      if (!i.image || !defaultImage) return;
      return i.image.id === defaultImage.id;
    }) || sortedImages?.[0]
  );

  const nextImage = useCallback(() => {
    const currentIndex = sortedImages.indexOf(image);
    const nextIdx = currentIndex + 1;
    setImage(sortedImages[nextIdx > sortedImages.length - 1 ? 0 : nextIdx]);
  }, [sortedImages, image]);

  const prevImage = useCallback(() => {
    const currentIndex = sortedImages.indexOf(image);
    const nextIdx = currentIndex - 1;
    setImage(sortedImages[nextIdx < 0 ? sortedImages.length - 1 : nextIdx]);
  }, [sortedImages, image]);

  return (
    <div
      className={clsx(
        className,
        'flex flex-col gap-0 overflow-x-hidden md:sticky md:top-[6rem] md:min-h-[calc(100vh-6rem)]'
      )}
      {...rest}>
      <div className={clsx('flex h-[450px] w-full items-center gap-2')}>
        {image && !!sortedImages?.length && (
          <button className="flex-1" onClick={prevImage}>
            <Icons.ArrowLeft className="svg-txt-color ml-2 mr-auto scale-150 transform" />
          </button>
        )}
        {image && (
          <GatsbyImage
            className="mx-auto"
            key={image.url}
            image={getImage(image.image as any)}
            alt={image.label || ''}
          />
        )}
        {image && !!sortedImages?.length && (
          <button className="flex-1" onClick={nextImage}>
            <Icons.ArrowLeft className="svg-txt-color ml-auto mr-2 rotate-180 scale-150 transform" />
          </button>
        )}
      </div>

      <div className="group relative z-10 flex-grow md:flex md:max-h-[calc(100vh-6rem-450px)] md:min-h-[theme(spacing.24)] md:flex-col md:hover:overflow-y-visible ">
        <ul className="m-0 flex flex-row gap-2 overflow-x-auto overflow-y-scroll p-2 md:grid md:grid-cols-[repeat(auto-fill,theme(spacing.20))] md:justify-center">
          {sortedImages?.map(mediaItem => (
            <li
              key={mediaItem.url}
              className={clsx(
                'h-20 w-20 shrink-0 cursor-pointer overflow-clip rounded-lg border-default border-gray-3 first:ml-auto last:mr-auto '
              )}
              onClick={() => setImage(mediaItem)}>
              <GatsbyImage
                className="object-cover"
                key={mediaItem.url}
                image={getImage(mediaItem.image as any)}
                alt={mediaItem.label || ''}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
