import { removeStyleAttr } from '@sus-core/utils/removeStyleAttr';
import React, { useMemo } from 'react';
import { Collapsible } from '../content/Collapsible';

export function ProductDescription({
  htmlContent,
  className,
}: {
  htmlContent?: string;
  className?: string;
}) {
  const withoutStyles = useMemo(
    () => removeStyleAttr(htmlContent),
    [htmlContent]
  );

  return (
    <div className={className}>
      <Collapsible show title="Beschreibung" htmlContent={withoutStyles} />
    </div>
  );
}
