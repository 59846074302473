import React from 'react';
import { OptionValueLabel } from './OptionValueLabel';
import { OptionBox } from './OptionBox';

import { useFormContext } from 'react-hook-form';
import { requiredValidation } from '@sus-core/components/form/validationRules';
import get from 'lodash/get';

type ProductOption =
  GatsbyTypes.ProductDetailsQuery['magentoProduct']['options'][0];
interface Props {
  option: ProductOption;
}
export function TextFieldOption({ option }: Props) {
  const { register, formState } = useFormContext();

  const error = get(formState.errors, `${option.option_id}`);

  return (
    <OptionBox
      error={error?.message}
      title={option.title}
      required={option.required}>
      <label className="flex">
        <OptionValueLabel
          title={''}
          price={option.field_value.price}
          type={option.field_value.price_type}
        />
      </label>
      <input
        {...register(option.option_id + '', {
          required: option.required ? requiredValidation : false,
        })}
        className="p-2 border border-ui-04"
        maxLength={option.field_value.max_characters}
        type="text"
      />
    </OptionBox>
  );
}
