import React from 'react';
import { CheckboxOption } from '@sus-core/components/product/options/CheckboxOption';
import { RadioOption } from '@sus-core/components/product/options/RadioOption';
import { DropDownOption } from '@sus-core/components/product/options/DropDownOption';
import { MultiSelectOption } from '@sus-core/components/product/options/MultiSelectOption';
import { TextFieldOption } from '@sus-core/components/product/options/TextFieldOption';

type ProductOptionsType =
  GatsbyTypes.ProductDetailsQuery['magentoProduct']['options'];
export interface ProductOptionsProps {
  options: ProductOptionsType;
}

export function ProductOptionsList({ options }: ProductOptionsProps) {
  return (
    <div className="grid gap-y-4">
      <div className="text-24">Konfiguration:</div>
      {options
        .concat()
        .sort((a, b) => {
          return a.sort_order - b.sort_order;
        })
        .map(option => (
          <React.Fragment key={option.option_id}>
            <ProductOption option={option} />
          </React.Fragment>
        ))}
    </div>
  );
}

const ProductOption: React.FC<{ option: ProductOptionsType[0] }> = ({
  option,
}) => {
  if (option.checkbox_value) {
    return <CheckboxOption option={option} />;
  }

  if (option.dropdown_value) {
    return <DropDownOption option={option} />;
  }

  if (option.multioptions_value) {
    return <MultiSelectOption option={option} />;
  }

  if (option.radio_value) {
    return <RadioOption option={option} />;
  }

  if (option.field_value) {
    return <TextFieldOption option={option} />;
  }

  return null;
};
