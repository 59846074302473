import React, { useCallback } from 'react';
import { useCurrency } from '@sus-core/hooks/store/useCurrency';
import clsx from 'clsx';
import { formatPrice, subtractTax } from '@sus-core/utils/price';

interface OptionLabelProps {
  title: string;
  price: number;
  type: string;
  classes?: { title?: string; price?: string };
}
export function OptionValueLabel({
  title,
  price,
  type,
  classes,
}: OptionLabelProps) {
  const label = (
    <span
      className={clsx('break-normal whitespace-normal', classes?.title)}
      dangerouslySetInnerHTML={{ __html: title }}></span>
  );

  if (!price) return label;

  switch (type) {
    case 'PERCENT':
      return (
        <>
          {label}
          <span className={clsx('font-normal', classes?.price)}>
            + {price} %
          </span>
        </>
      );

    default:
      return (
        <>
          {label}
          <span className={clsx('font-normal', classes?.price)}>
            + {formatPrice(subtractTax(price))} /{' '}
            <span>
              {formatPrice(price)}{' '}
              <span className="text-11 text-gray-2">inkl.MwSt.</span>
            </span>
          </span>
        </>
      );
  }
}

export function makeOptionValueString(
  title: string,
  price: number,
  type: string,
  currencySymbol: string
) {
  if (!price) {
    return `${title}`;
  }

  switch (type) {
    case 'PERCENT':
      return `${title} | + ${price}%`;
    default:
      return `${title} | + ${formatPrice(subtractTax(price))} (${formatPrice(
        price
      )} inkl.MwSt.)`;
  }
}

export function useOptionValueString(): (
  title: string,
  price: number,
  type: string
) => string {
  const { default_display_currency_symbol } = useCurrency();

  const fn = useCallback(
    (title: string, price: number, type: string) =>
      makeOptionValueString(
        title,
        price,
        type,
        default_display_currency_symbol
      ),
    [default_display_currency_symbol]
  );

  return fn;
}
