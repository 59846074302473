import React from 'react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { OptionBox } from './OptionBox';
import { useOptionValueString } from './OptionValueLabel';
import { requiredValidation } from '@sus-core/components/form/validationRules';

type ProductOption =
  GatsbyTypes.ProductDetailsQuery['magentoProduct']['options'][0];
interface Props {
  option: ProductOption;
}
export function DropDownOption({ option }: Props) {
  const { register, formState } = useFormContext();
  const valueLabelFactory = useOptionValueString();

  const error = get(formState.errors, `${option.option_id}`);
  return (
    <OptionBox
      error={error?.message}
      title={option.title}
      required={option.required}>
      <select
        className="border-default border-gray-4 text-15 px-4 py-2 rounded-lg hover:bg-blue-3 w-full"
        {...register(option.option_id + '', {
          required: option.required ? requiredValidation : false,
        })}>
        <option value="">--Bitte wählen Sie eine Option aus--</option>
        {option.dropdown_value.map(value => (
          <option
            className="break-words overflow-ellipsis w-4 whitespace-normal overflow-x-hidden"
            key={value.option_type_id}
            value={value.option_type_id}>
            {valueLabelFactory(value.title, value.price, value.price_type)}
          </option>
        ))}
      </select>
    </OptionBox>
  );
}
