import { useStaticQuery, graphql } from 'gatsby';

export function useCurrency() {
  const data = useStaticQuery<GatsbyTypes.CurrencyQuery>(graphql`
    query Currency {
      sus {
        currency {
          base_currency_code
          base_currency_symbol
          default_display_currency_code
          default_display_currency_symbol
          available_currency_codes
        }
      }
    }
  `);

  return data.sus.currency;
}
