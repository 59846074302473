import isNumber from 'lodash/isNumber';
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AddToCartButton } from '../cart/AddToCartButton';
import { ProductOptionsList } from './ProductOptions';
import { useCartService } from '../../state/xstate/cart/useCartService';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';
import { CustomizableOptionInput } from 'src/generated/api.types';

export function ProductAddToCartForm({
  className,
  product,
  onSelection,
}: {
  className?: string;
  product: GatsbyTypes.ProductDetailsQuery['magentoProduct'];
  onSelection: (selection: OptionValue[]) => void;
}) {
  const methods = useForm({
    mode: 'all',
    defaultValues: { amount: product.min_sales_qty || 1 },
  });
  const [selection, setSelection] = useState<OptionValue[]>();

  const { add: addProduct, loading } = useCartService();
  const { openCart } = useAppService();

  const { getValues } = methods;

  const { options } = product;

  const handleChange = useCallback(() => {
    const selection = valuesToOptionValues(getValues(), options);
    setSelection(selection);
    onSelection?.(selection);
  }, [getValues, onSelection, options]);

  const handleAddToCart = useCallback(
    (quantity: number) => {
      const options = selection?.map<CustomizableOptionInput>(sel => ({
        id: sel.optionId,
        value_string: sel.value,
      }));
      addProduct(product, quantity, options).then(() => {
        openCart();
      });
    },
    [selection, addProduct, product, openCart]
  );

  const hasRequiredOptions = options?.some(opt => opt.required) || false;

  const needsOptionSelection =
    (hasRequiredOptions && !methods.formState.isValid) || false;

  return (
    <FormProvider {...methods}>
      <form
        className={className}
        onChange={handleChange}
        onSubmit={methods.handleSubmit(values => {
          handleAddToCart(values.amount);
        })}>
        {options && <ProductOptionsList options={product.options} />}
        {hasRequiredOptions && (
          <p className="mt-4">
            <sup>*</sup> erforderlich
          </p>
        )}
        {!!options?.length && <hr className="border-gray-4 my-6" />}
        {needsOptionSelection && (
          <div>
            <p>
              Bitte wählen Sie die erforderlichen Optionen aus, um den Artikel
              in der Warenkorb legen zu können.
            </p>
          </div>
        )}
        <AddToCartButton
          minAmount={product.min_sales_qty}
          loading={loading}
          showAmount
          type="submit"
        />
      </form>
    </FormProvider>
  );
}

function valuesToOptionValues(
  values: Record<string, any>,
  options: GatsbyTypes.ProductDetailsQuery['magentoProduct']['options'] = []
): OptionValue[] {
  return Object.entries(values)
    .filter(([, value]) => {
      // remove clutter (emtpy arrays and undefined values)
      if (Array.isArray(value)) {
        return value.length > 0;
      } else if (isNumber(value)) {
        return true;
      }
      return value;
    })
    .map(([key, value]) => {
      const option = options?.find(
        option => option.option_id === parseInt(key)
      );

      return getSelectedOptionValues(value, option);
    })
    .reduce((flat, i) => [...flat, ...i], []);
}

export interface OptionValue {
  optionId: number;
  price?: number;
  price_type?: string;
  sku?: string;
  meta?: any;
  value: string;
}

type ProductOption =
  GatsbyTypes.ProductDetailsQuery['magentoProduct']['options'][0];

export function getSelectedOptionValues(
  value: string | string[],
  option?: GatsbyTypes.ProductDetailsQuery['magentoProduct']['options'][0]
): OptionValue[] {
  if (!option) return [];

  if (
    option.checkbox_value ||
    option.dropdown_value ||
    option.radio_value ||
    option.multioptions_value
  ) {
    const valueIds: string[] = Array.isArray(value) ? value : [value];
    const optionValues: readonly (
      | ProductOption['checkbox_value'][0]
      | ProductOption['dropdown_value'][0]
      | ProductOption['radio_value'][0]
      | ProductOption['multioptions_value'][0]
    )[] =
      option.checkbox_value ||
      option.dropdown_value ||
      option.radio_value ||
      option.multioptions_value ||
      [];

    const selectedValues = valueIds
      .map(valueId => {
        const optionValue = optionValues?.find(
          val => val.option_type_id + '' === valueId
        );
        return optionValue ? { ...optionValue, value: valueId } : null;
      })
      .filter(_ => _)
      .map(o => ({
        ...o,
        optionId: option.option_id,
      }));

    return selectedValues;
  }

  const textValue = value as string;
  const optValue = option.field_value;

  return optValue
    ? [
        {
          ...optValue,
          optionId: option.option_id,
          value: textValue,
          meta: { text: textValue },
        },
      ]
    : [];
}
