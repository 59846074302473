import { DeliveryTimes_customAttributeMetadata_items_attribute_options } from 'src/generated/api.types';
import { graphql, useStaticQuery } from 'gatsby';

export function useDeliveryTimes(): DeliveryTimes_customAttributeMetadata_items_attribute_options[] {
  const deliveryTimeOptions = useStaticQuery(graphql`
    query {
      sus {
        customAttributeMetadata(
          attributes: {
            attribute_code: "sus_delivery_time"
            entity_type: "catalog_product"
          }
        ) {
          items {
            attribute_options {
              label
              value
            }
          }
        }
      }
    }
  `);

  return deliveryTimeOptions?.sus?.customAttributeMetadata?.items?.[0]
    ?.attribute_options;
}
