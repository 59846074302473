import React from 'react';
import clsx from 'clsx';
import { graphql } from 'gatsby';
import { useDeliveryTimes } from '@sus-core/hooks/store/useDeliveryTimes';

export interface Props {
  product: Pick<
    GatsbyTypes.MagentoProduct,
    'stock_status' | 'sus_delivery_time'
  >;
}
export function StockStatus({ product }: Props) {
  const deliveryTimeOptions = useDeliveryTimes();
  const inStock = product.stock_status === 'IN_STOCK';
  const deliveryTime = deliveryTimeOptions.find(
    i => parseInt(i.value) === product.sus_delivery_time
  );

  const status = inStock ? 'Auf Lager' : 'aktuell nicht vefügbar';

  return (
    <div className="flex flex-col">
      {!deliveryTime && (
        <span>
          Verfügbarkeit:{' '}
          <span
            className={clsx(
              'ml-2 font-normal',
              inStock ? 'text-success' : 'text-error'
            )}>
            {status}
          </span>
        </span>
      )}

      {!!deliveryTime && <span>Lieferzeit: {deliveryTime.label}</span>}
    </div>
  );
}

export const stockStatusFragment = graphql`
  fragment StockStatusFragment on SUS_ProductInterface {
    stock_status
    only_x_left_in_stock
  }
`;
