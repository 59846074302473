import React from 'react';
import { OptionBox } from './OptionBox';
import { graphql } from 'gatsby';
import get from 'lodash/get';

import { OptionValueLabel } from './OptionValueLabel';
import { useFormContext } from 'react-hook-form';
import { requiredValidation } from '@sus-core/components/form/validationRules';

type ProductOption =
  GatsbyTypes.ProductDetailsQuery['magentoProduct']['options'][0];
interface Props {
  option: ProductOption;
}
export function RadioOption({ option }: Props) {
  const { register, formState } = useFormContext();

  const error = get(formState.errors, `${option.option_id}`);

  return (
    <OptionBox
      error={error?.message}
      title={option.title}
      required={option.required}>
      <ul className="border-default border-gray-4 text-15 rounded-lg">
        {option.radio_value.map(value => {
          const domId = `${option.option_id}-${value.option_type_id}`;
          return (
            <li
              className="px-4 py-2 flex items-center gap-4 border-b-default border-gray-4 hover:bg-blue-3 last:border-none"
              key={value.title}>
              <input
                id={domId}
                {...register(option.option_id + '', {
                  required: option.required ? requiredValidation : false,
                })}
                value={value.option_type_id}
                type="radio"
              />
              <label htmlFor={domId} className="grid">
                <OptionValueLabel
                  price={value.price}
                  title={value.title}
                  type={value.price_type}
                />
              </label>
            </li>
          );
        })}
      </ul>
    </OptionBox>
  );
}

export const radioOptionFragment = graphql`
  fragment RadioOptionFragment on SUS_CustomizableRadioOption {
    radio_value: value {
      option_type_id
      price
      price_type
      sku
      sort_order
      title
    }
  }
`;
