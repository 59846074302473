import clsx from 'clsx';
import React, { ReactNode, useState } from 'react';
import { Icons } from '../icon/Icons';

export function Collapsible({
  title,
  htmlContent,
  children,
  className,
  show: defaultShow,
}: {
  show?: boolean;
  title: string;
  htmlContent?: string;
  children?: ReactNode;
  className?: string;
}) {
  const [show, setShow] = useState<boolean>(!!defaultShow);
  return (
    <div className={clsx('bg-white-1 rounded-lg shadow-sm', className)}>
      <div
        className="flex items-center p-5 cursor-pointer text-17 text-gray-1 font-medium"
        onClick={() => setShow(!show)}>
        {show ? (
          <Icons.Minus className="mx-4 flex-grow-0 flex-shrink-0" />
        ) : (
          <Icons.Plus className="mx-4 flex-grow-0 flex-shrink-0" />
        )}
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </div>

      {htmlContent && (
        <div
          className={clsx('border-t-2 border-gray-5 p-4', !show && 'hidden')}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      )}
      {children && (
        <div
          className={clsx('border-t-2 border-gray-5 p-4', !show && 'hidden')}>
          {children}
        </div>
      )}
    </div>
  );
}
