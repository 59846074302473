import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

interface Props
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'children'> {
  title: string;
  required: boolean;
  error?: string;
}

export function OptionBox({
  title,
  required,
  children,
  className,
  error,
}: Props) {
  return (
    <div
      className={clsx(
        className,
        'grid gap-2 overflow-x-hidden',
        error &&
          'border-danger pl-2 pt-2 pb-2 border-l-2 bg-red-3 bg-opacity-20 focus:bg-white-1'
      )}>
      <div className="text-15 font-semibold">
        {title} {required && <sup>*</sup>}
      </div>
      {children}

      {error && <div className="p-2 -ml-2 font-bold text-danger">{error}</div>}
    </div>
  );
}
