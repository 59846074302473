import React from 'react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { OptionValueLabel } from './OptionValueLabel';
import { OptionBox } from './OptionBox';
import { graphql } from 'gatsby';
import { requiredValidation } from '@sus-core/components/form/validationRules';

type ProductOption =
  GatsbyTypes.ProductDetailsQuery['magentoProduct']['options'][0];
interface Props {
  option: ProductOption;
}
export function CheckboxOption({ option }: Props) {
  const { register, formState } = useFormContext();

  const error = get(formState.errors, `${option.option_id}`);

  return (
    <OptionBox
      error={error?.message}
      title={option.title}
      required={option.required}>
      <ul className="border-default border-gray-4 text-15 rounded-lg">
        {option.checkbox_value.map((value, idx) => {
          const domId = `${idx}-${option.option_id}-${value.option_type_id}`;
          return (
            <li
              className="px-4 py-2 flex items-center gap-4 border-b-default border-gray-4 last:border-none hover:bg-blue-3"
              key={domId}>
              <input
                id={domId}
                {...register(`${option.option_id}`, {
                  required: option.required ? requiredValidation : false,
                })}
                type="checkbox"
                value={value.option_type_id}
              />
              <label htmlFor={domId} className="grid">
                <OptionValueLabel
                  price={value.price}
                  title={value.title}
                  type={value.price_type}
                />
              </label>
            </li>
          );
        })}
      </ul>
    </OptionBox>
  );
}

export const checkboxOptionFragment = graphql`
  fragment CheckboxOptionFragment on SUS_CustomizableCheckboxOption {
    checkbox_value: value {
      option_type_id
      price
      price_type
      sku
      sort_order
      title
    }
  }
`;
