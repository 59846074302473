import React, { useCallback, useState } from 'react';
import { StockStatus } from './StockStatus';
import { PriceRange } from './price/PriceRange';

import { ProductAddToCartForm, OptionValue } from './ProductAddToCartForm';
import clsx from 'clsx';
import { PriceValue } from './price/PriceValue';

export function SimpleProductView({
  product,
  className
}: {
  product: GatsbyTypes.ProductDetailsQuery['magentoProduct'];
  className?: string;
}) {
  const [optionsPrice, setOptionsPrice] = useState<number>(0);

  const handleOptionSelection = useCallback(
    (selection: OptionValue[]) => {
      //calculate price adjustment for selected options
      const priceAdjustments = selection.map(optionValue => {
        if (optionValue.price_type === 'FIXED') {
          return optionValue.price;
        }

        if (optionValue.price_type === 'PERCENT') {
          const regularPrice =
            product.price_range.minimum_price.regular_price.value;
          return regularPrice * (optionValue.price / 100);
        }

        // TODO handle dynamic prices
        return 0;
      });

      setOptionsPrice(
        priceAdjustments.reduce((amount, price) => amount + price, 0)
      );
    },
    [product.price_range.minimum_price.regular_price.value]
  );

  return (
    <div className={clsx('flex flex-col p-2 pb-8 pt-0', className)}>
      <h1 className="m-0 text-26">{product.name}</h1>
      <PriceRange
        className="self-end"
        priceAdjustment={optionsPrice}
        priceRange={product.price_range}
      />

      {!!product.price_tiers?.length && (
        <TierPricesBox prices={product.price_tiers} />
      )}

      <hr className="my-6 border-gray-4" />

      <ProductAddToCartForm
        onSelection={handleOptionSelection}
        product={product}
      />
      <hr className="my-6 border-gray-4" />
      <StockStatus product={product} />
    </div>
  );
}

function TierPricesBox({
  prices
}: {
  prices?: GatsbyTypes.MagentoProduct['price_tiers'];
}) {
  return (
    <div className="b-box-plain my-2 bg-white-2 p-4 px-6">
      {prices?.map((tierPrice, idx) => (
        <div key={idx} className="my-1">
          Kaufen Sie {tierPrice.quantity} zum Preis von je{' '}
          <PriceValue value={tierPrice.final_price} /> und{' '}
          <span className="underline">
            sparen {tierPrice.discount.percent_off}%
          </span>
        </div>
      ))}
    </div>
  );
}
