import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OptionBox } from './OptionBox';
import get from 'lodash/get';

import { requiredValidation } from '@sus-core/components/form/validationRules';
import { OptionValueLabel } from './OptionValueLabel';

type ProductOption =
  GatsbyTypes.ProductDetailsQuery['magentoProduct']['options'][0];
interface Props {
  option: ProductOption;
}
export function MultiSelectOption({ option }: Props) {
  const { register, formState } = useFormContext();

  const error = get(formState.errors, `${option.option_id}`);

  return (
    <OptionBox
      title={option.title}
      required={option.required}
      error={error?.message}>
      <div className="border-default border-gray-4 text-15 rounded-lg">
        {option.multioptions_value?.map(value => {
          const domId = `${option.option_id}-${value.option_type_id}`;
          return (
            <div
              key={domId}
              className="flex items-center py-2 px-4 gap-4 border-b-default border-b-gray-4 hover:bg-blue-3 last:border-none">
              <input
                id={domId}
                {...register(option.option_id + '', {
                  required: option.required ? requiredValidation : false,
                })}
                type="checkbox"
                value={value.option_type_id}
              />
              <label htmlFor={domId} className="grid">
                <OptionValueLabel
                  price={value.price}
                  title={value.title}
                  type={value.price_type}
                />
              </label>
            </div>
          );
        })}
      </div>
    </OptionBox>
  );
}
