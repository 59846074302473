import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Product } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';

import Layout from '@sus-core/components/scaffolding/Layout';
import { ProductImages } from '@sus-core/components/product/ProductImages';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import { SimpleProductView } from '@sus-core/components/product/SimpleProductView';
import { ProductDescription } from '@sus-core/components/product/ProductDescription';
import clsx from 'clsx';
import { useTracking } from '@sus-core/hooks/tracking/useTracking';
import { WindowLocation } from '@reach/router';
import { SuSLocationState } from '@sus-core/components/navigation/SuSLocationState';
import { PageProvider } from '@sus-core/hooks/page/PageContext';
import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';
import { useSiteMetadata } from '@sus-core/hooks/useSiteMetadata';
import { ProductAttributes } from '@sus-core/components/product/ProductAttributes';

type ProductPageProps = SusPageProps<
  GatsbyTypes.ProductDetailsQuery,
  { sku: string; updated_at: string }
>;

function ProductPage({ location, pageContext, ...props }: ProductPageProps) {
  const product = props.data.magentoProduct; //props.data.sus.products.items[0];
  const attributes = []; //props.data.sus.attributeValues;

  // @TODO handle other product types...
  if (product?._xtypename === 'SimpleProduct') {
    return (
      <SimpleProductPage
        pageContext={pageContext}
        location={location}
        attributes={attributes}
        product={product}
      />
    );
  }

  return (
    <PageProvider
      data={{
        pageType: 'Detail',
        attributeMetaData: pageContext.attributeMetaData
      }}>
      <Layout
        location={location}
        meta={{
          title: 'Unbekanntes Produkt'
        }}>
        <div className="flex flex-col items-center">
          <h1>Produkt nicht verfügbar</h1>
          <p>Leider ist das gewünschte Produkt aktuell nicht verfügbar.</p>
          <p>
            Sollten Sie ein bestimmtes Produkt suchen, kontaktieren Sie uns:
          </p>
          <div className="flex flex-col">
            <span>
              Mail:
              <a className="p-2" href="mailto:office@schrank-und-stuhl.de">
                office@schrank-und-stuhl.de
              </a>
            </span>
            <span>
              Telefon:{' '}
              <a className="p-2" href="tel:00493036434470">
                +49 30 36434470
              </a>
            </span>
          </div>
        </div>
      </Layout>
    </PageProvider>
  );
}

function useProductStructuredData(
  product: Pick<
    GatsbyTypes.ProductDetailsQuery['magentoProduct'],
    'name' | 'price_range' | 'media_gallery' | 'meta_description' | 'sku'
  >
) {
  const [data, setData] = useState<any>();
  const siteMeta = useSiteMetadata();
  useEffect(() => {
    const price =
      product.price_range?.minimum_price?.final_price ||
      product.price_range?.maximum_price?.final_price;
    setData(
      helmetJsonLdProp<Product>({
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: product.name,
        brand: {
          '@type': 'Brand',
          name: siteMeta.title
        },
        image:
          product.media_gallery?.filter(i => !i.disabled)?.map(i => i.url) ||
          [],
        description: product.meta_description,
        sku: product.sku,
        itemCondition: 'NewCondition',
        offers: {
          '@type': 'Offer',
          availability: 'InStock',
          price: price?.value,
          priceCurrency: price?.currency
        }
      })
    );
  }, []);

  return data;
}

function SimpleProductPage({
  product,
  location,
  pageContext,
  attributes
}: {
  product: GatsbyTypes.ProductDetailsQuery['magentoProduct'];
  location: WindowLocation<SuSLocationState>;
  pageContext: ProductPageProps['pageContext'];
  attributes: any; //GatsbyTypes.ProductDetailsQuery['sus']['attributeValues'];
}) {
  const {
    name,
    meta_title,
    meta_keyword,
    meta_description,
    canonical_url,
    image
  } = product;

  const { productDetailImpression } = useTracking();

  useEffect(() => {
    productDetailImpression(product, location.state?.prev?.category);
  }, []);

  const productData = useProductStructuredData(product);

  return (
    <PageProvider
      data={{
        pageType: 'Detail',
        attributeMetaData: pageContext.attributeMetaData
      }}>
      <Layout
        location={location}
        meta={{
          title: meta_title,
          keywords: meta_keyword,
          description: meta_description,
          canonical: canonical_url,
          robots: ['index', 'follow']
        }}
        seo={{
          title: name,
          type: 'product',
          description: meta_description,
          image: image?.publicURL
        }}
        showBreadcrumbs
        breadcrumbs={pageContext.breadcrumbs}
        currentPageName={name}
        structuredData={[productData]}>
        <div className={clsx(PAGE_CONTAINER_CSS, 'flex flex-col md:flex-row')}>
          <ProductImages
            product={product}
            className="flex-1 md:mr-8 md:self-start"
          />
          <SimpleProductView className="md:w-1/2 lg:w-1/3" product={product} />
        </div>

        <div className="-mb-16 flex flex-1 flex-col gap-y-4 bg-gray-5 py-16">
          <h2 className="mb-16 text-center">Produktdetails</h2>
          <ProductDescription
            className={clsx(PAGE_CONTAINER_CSS, '')}
            htmlContent={product.description?.html}
          />
          {attributes?.length > 0 && (
            <ProductAttributes
              className={clsx(PAGE_CONTAINER_CSS)}
              attributes={attributes}
            />
          )}
        </div>
      </Layout>
    </PageProvider>
  );
}

export default ProductPage;

/**
 * Fragments don´t seem to work properly in current magento (v2.3). 2.4 might have a fix.
 * Until then we#ll have to put everything in the page query directly.
 */

/*
attributeValues(sku: $sku) {
        label
        value
        code
        input_type
        attribute_type
        position
      }
*/

export const query = graphql`
  query ProductDetails($sku: String!) {
    magentoProduct(sku: { eq: $sku }) {
      id
      _xtypename
      canonical_url
      categories {
        id
        name
        path
        url_key
        url_path
      }
      color
      description {
        html
      }
      image {
        id
        publicURL
      }
      media_gallery {
        disabled
        label
        position
        url

        image {
          id
          childImageSharp {
            gatsbyImageData(height: 450)
          }
        }
      }
      meta_description
      meta_keyword
      meta_title
      min_sales_qty
      name
      options {
        required
        title
        sort_order
        option_id
        checkbox_value {
          price
          title
          sort_order
          option_type_id
          price_type
        }
        dropdown_value {
          title
          price
          option_type_id
          price_type
          sku
        }
        date_value {
          price
          sku
          price_type
        }
        field_value {
          price
          sku
          price_type
          max_characters
        }
        multioptions_value {
          option_type_id
          title
          price_type
          price
          sku
        }
        radio_value {
          option_type_id
          price
          title
          sku
          price_type
        }
        area_value {
          max_characters
          price
          price_type
        }
      }
      price_range {
        maximum_price {
          final_price {
            currency
            value
          }
          discount {
            amount_off
            percent_off
          }
          regular_price {
            currency
            value
          }
        }
        minimum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
      }
      sku
      stock_status
      sus_delivery_time
      small_image {
        childImageSharp {
          gatsbyImageData(height: 450)
        }
      }
      price_tiers {
        discount {
          amount_off
          percent_off
        }
        final_price {
          currency
          value
        }
        quantity
      }
    }
  }
`;
